import React, { useState, useEffect } from 'react';

const ProgressivePlatform = ({ steps }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [expandedSteps, setExpandedSteps] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setActiveStep((prev) => {
            const nextStep = (prev + 1) % steps.length;
            if (nextStep === 0) {
              setExpandedSteps([]);
            } else {
              setExpandedSteps(prev => [...prev, prev]);
            }
            return nextStep;
          });
          return 0;
        }
        return Math.min(oldProgress + 1, 100);
      });
    }, 50);

    return () => clearInterval(timer);
  }, [steps.length]);

  return (
    <div className="grid lg:grid-cols-2 relative bg-[#133757] rounded-[32px] px-2 pt-4 md:px-2 mb-[50px] overflow-hidden"
      style={{
        background: "linear-gradient(90deg, rgba(237,243,253,1) 1%, rgba(235,243,252,1) 10%, rgba(175,224,229,1) 100%)"
      }}
    >
      {/* Left side - Phone mockup */}
      <div className="lg:sticky lg:top-0 lg:bg-transparent bg-[#FFFFFF70] lg:rounded-none rounded-[32px]">
        <div className="h-full w-full flex items-end">
          <div className="w-full mx-auto">
            <img
              src={steps[activeStep].image}
              alt="Phone interface"
              className="w-full"
            />
          </div>
        </div>
      </div>

      {/* Right side - Content */}
      <div className="p-4 sm:p-6 lg:p-8 lg:pl-12">
        <div className="flex flex-col lg:text-left text-center">
          <h2 className="text-5xl font-light mb-4 sm:mb-6 lg:mb-8 text-black">
            Tüm İşlemler <span className="font-semibold">Tek Platformda</span>
          </h2>
          <p className="text-[#2B3C48] text-2xl mb-6 lg:mb-8">
            DyorEX'te DTL alım-satım sürelerinizi etkin bir şekilde yönetin ve platform üzerindeki cüzdanınızda kripto varlıklarınızın transferini kolayca sağlayın.
          </p>
        </div>

        {/* Steps - Minimum height container to prevent layout shift */}
        <div className="space-y-4 sm:space-y-5 lg:space-y-6 lg:min-h-[400px]">
          {steps.map((step, index) => {
            const isActive = index === activeStep;
            const isExpanded = expandedSteps.includes(index) || isActive;

            return (
              <div
                key={index}
                className={`transition-all duration-300 ${index > activeStep && !expandedSteps.includes(index) ? 'opacity-50' : ''}`}
              >
                <div className="relative">
                  {isActive && (
                    <div className="absolute -top-1 left-0 right-0">
                      <div className="w-full bg-gray-100 rounded-full h-1">
                        <div
                          className="bg-[#133757] h-1 rounded-full transition-all duration-300"
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className={`bg-white/80 rounded shadow-sm overflow-hidden transition-all duration-500 ease-in-out
                      ${isExpanded ? 'h-auto' : 'h-[72px] sm:h-[68px] lg:h-16'}`}
                  >
                    <div className="p-3 sm:p-4">
                      <div className="flex items-start gap-2 sm:gap-3">
                        <div
                          style={{
                            background: "radial-gradient(circle, rgba(105, 220, 227, 1) 0%, rgba(19, 55, 87, 1) 100%)"
                          }}
                          className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center"
                        >
                          <span className="text-white text-sm">{index + 1}</span>
                        </div>
                        <div className="flex-grow min-w-0">
                          <div className="flex items-center justify-between">
                            <h3 className="font-medium text-black text-base lg:text-[22px] leading-[27px] truncate">
                              {step.title}
                            </h3>
                          </div>
                          <div
                            className={`transition-all duration-500 ${isExpanded ? 'opacity-100 mt-1' : 'opacity-0 h-0'}`}
                          >
                            <p className="text-base lg:text-lg text-[#404040]">{step.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProgressivePlatform;