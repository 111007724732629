import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Circle, Triangle, ArrowRight, ArrowLeft } from 'lucide-react';

import ArtBoard from '../assets/images/img/artBoard.png';

const TimelineSlider = () => {
    const timelineData = [
        {
            id: 1,
            isQuarter: true,
            title: "2024 Q1",
        },
        {
            id: 2,
            title: "D'Lira Testneti'in Başlatılması",
            quarter: "Q1"
        },
        {
            id: 3,
            title: "D'Lira Mainnet'in Başlatılması",
            quarter: "Q1"
        },
        {
            id: 4,
            title: "D'Lira Mainnet Blok Geçişinin Başlatılması",
            quarter: "Q1"
        },
        {
            id: 5,
            title: "Akıllı Sözleşme Denetimleri",
            quarter: "Q1"
        },
        {
            id: 6,
            isQuarter: true,
            title: "2024 Q2",
        },
        {
            id: 7,
            title: "Hata Ödülü (bug bounty) Programı",
            quarter: "Q2"
        },
        {
            id: 8,
            title: "Yazılım geliştirme kitleri (SDK) ve diğer geliştirme araçlarının sunulması",
            quarter: "Q2"
        },
        {
            id: 9,
            title: "Çoklu zincir ve zincirler arası köprü mekanizmalarının oluşturulması",
            quarter: "Q2"
        },
        {
            id: 10,
            isQuarter: true,
            title: "2024 Q3"
        },
        {
            id: 11,
            title: "Staking arayüzü geliştirilmesi",
            quarter: "Q3"
        },
        {
            id: 12,
            title: "Geri bildirimler temel alınarak sistem iyileştirmelerinin yapılması",
            quarter: "Q3"
        },
        {
            id: 13,
            title: "Ekosistem projeleri: dLira Dağıtık Uygulamaları ve Hackathon etkinlikleri",
            quarter: "Q3"
        },
        {
            id: 14,
            isQuarter: true,
            title: "2024 Q4"
        },

        {
            id: 15,
            title: "Yardım kuruluşları için işlem ücreti iyileştirmelerinin uygulanması",
            quarter: "Q4"
        }

    ];

    return (
        <div className="w-full p-8 pb-24 rounded-[32px] relative "
            style={{
                background: `url('${ArtBoard}')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#133757'

            }}>
            <div className="max-w-6xl mx-auto">
                <h2 className="text-white text-5xl font-bold mb-16">D'Lira Yol Haritası</h2>

                <div className="relative px-12">
                    <div className="absolute left-12 right-12 bottom-4 h-0.5 bg-[#78CCD4]"></div>

                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={0}
                        slidesPerView={5}
                        navigation={{
                            prevEl: '.swiper-button-next', // Swapped these
                            nextEl: '.swiper-button-prev', // Swapped these
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                        className="timeline-slider"
                    >
                        {timelineData.map((item, index) => (
                            <SwiperSlide key={item.id}>
                                <div className="flex flex-col items-start">
                                    <div className="h-[80px] flex justify-center items-end mb-4">
                                        <p
                                            className={`text-white ${item.isQuarter
                                                ? 'text-xl font-bold [writing-mode:vertical-lr] transform rotate-180'
                                                : 'text-sm'
                                                }`}
                                        >
                                            {item.title}
                                        </p>
                                    </div>

                                    <div className="h-8 flex items-start">
                                        {item.isQuarter ? (
                                            <Circle className="w-[25px] h-[25px] text-white" style={{ fill: 'currentcolor', transform: 'translateY(2px)' }} />
                                        ) : (
                                            <Triangle className="w-[22px] h-[22px] text-white" style={{ fill: 'currentcolor', transform: 'translateY(2px)' }} />
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>

            {/* Navigation buttons positioned at bottom right with more space from Swiper */}
            <div className="absolute bottom-8 right-[250px] flex gap-2">
                <button className="swiper-button-next bg-white hover:bg-blue-50 transition-colors w-[28px] h-[28px] rounded-full shadow-lg flex items-center justify-center">
                    <ArrowLeft size={16} color='black' />
                </button>
                <button className="swiper-button-prev bg-white hover:bg-blue-50 transition-colors w-5 h-5 rounded-full shadow-lg flex items-center justify-center">
                    <ArrowRight size={16} color='black' />
                </button>
            </div>

            <style>{`
        .timeline-slider .swiper-button-disabled {
          opacity: 0.35;
          cursor: not-allowed;
          background-color: #e5e7eb !important;
        }
        
        .timeline-slider .swiper-slide {
          height: auto;
        }
        .swiper-button-prev {
                width: 28px;
                height: 28px;
        }

        .swiper-button-next {
                width: 28px;
                height: 28px;
        }
        .swiper-button-prev::after,
        .swiper-button-next::after {
          display: none;
        }
      `}</style>
        </div>
    );
};

export default TimelineSlider;