import React from 'react'
import { Outlet } from "react-router-dom"
import Navbar from './layouts/Navbar'
import Footer from './layouts/Footer'



const Layout = () => {
    return (
        <>

            <Navbar />
            <div className=" mx-auto pt-[85px] bg-white" id='main' >
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Layout