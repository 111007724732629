import React from 'react'
import Hero from '../components/Hero'
import Section from '../components/Section'
import WhatIsDtl from '../assets/images/img/whatIsDtl.png'
import Slide1 from '../assets/images/svg/slide1.svg'
import Slide2 from '../assets/images/svg/slide2.svg'
import Slide3 from '../assets/images/svg/slide3.svg'
import Slide4 from '../assets/images/svg/slide4.svg'
import OnboardingStepCard from '../components/OnboardingStepCard';
import Button from '../components/Button';
import FeaturesBanner from '../assets/images/img/features-banner.png'
import FeaturesBannerMobile from '../assets/images/img/FeaturesBanner_mobile.png'
import FeaturesBanner2 from '../assets/images/img/features-banner-2.png'
import FeaturesBanner2Mobile from '../assets/images/img/FeaturesBanner2_mobile.png'
import FeaturesBanner3 from '../assets/images/img/features-banner-3.png'
import FeaturesBanner3Mobile from '../assets/images/img/FeaturesBanner3_mobile.png'
import FeaturesBanner4 from '../assets/images/img/features-banner-4.png'
import FeaturesBanner4Mobile from '../assets/images/img/FeaturesBanner4_mobile.png'
import Accordion from '../components/Accordion';
import { Mail, Phone } from 'lucide-react';
import ContactForm from '../components/ContactForm';
import ProgressivePlatform from '../components/ProgressivePlatform';
import PlatformBanner from '../assets/images/img/platform-banner.png'
import PlatformBanner2 from '../assets/images/img/platform-banner-2.png'
import PlatformBanner3 from '../assets/images/img/platform-banner-3.png'
import RoadMap from '../components/RoadMap';


const Home = () => {
    const steps = [
        {
            number: "1",
            image: Slide1,
            title: "Kullanıcı aracı platformda hesap oluşturur ve kimlik doğrulaması gerçekleştirir."
        },
        {
            number: "2",
            image: Slide2,
            title: "Kullandığı platforma Türk Lirası yatırır."
        },
        {
            number: "3",
            image: Slide3,
            title: "Kullanıcı, platformdan satın almak istediği miktarda D’Lira satın alır."
        },
        {
            number: "4",
            image: Slide4,
            title: "D’Liralarını ... ağlarının desteklendiği her yerde kullanır."
        }
    ];

    const accordionItems = [
        {
            id: 1,
            title: "D’Lira (DLIRA) nedir?",
            content: "D’Lira (DLIRA), Türk Lirası’na sabitlenmiş bir stablecoin’dir. 1 dLira, 1 Türk Lirası'na eşdeğer olacak şekilde tasarlanmıştır ve bu sayede kripto para piyasasındaki fiyat dalgalanmalarından korunarak işlemler gerçekleştirmenizi sağlar."
        },
        {
            id: 2,
            title: "DLIRA Token’in Yatırımcı için Sağladığı Avantajlar Nelerdir?",
            content: [
                "Fiyat istikrarı: Değeri 1 Türk Lirası'na sabitlendiği için volatilite riskine karşı korur.",
                "Hızlı ve düşük maliyetli işlemler: Özellikle sınır ötesi para transferlerinde hızlı ve düşük maliyetli transferler yapılabilir.",
                "DeFi fırsatları: Merkeziyetsiz finans uygulamalarında kullanılabilir, staking, yield farming gibi gelir modellerinden yararlanılabilir.",
                "7/24 işlem: DLIRA ile istediğiniz zaman işlem yapabilirsiniz, bankaların çalışma saatleri ile sınırlı değilsiniz.",
                "Güvenilirlik ve şeffaflık: Blokzincir üzerinde yapılan işlemler şeffaf ve takip edilebilir."
            ],
            contentType: "list"
        },
        {
            id: 3,
            title: "DLIRA nasıl satın alınır?",
            content: "D’Lira'yı almak için öncelikle D’Lira’yı listeleyen kripto para borsalarından birinde hesap oluşturmanız ve Türk Lirası yatırarak D’Lira satın almanız gerekir. Ayrıca, merkeziyetsiz borsalarda (DEX) da D’Lira ticareti yapılabilir."
        },
        {
            id: 4,
            title: "DLIRA kullanım alanları nelerdir?",
            content: (
                <div className="space-y-4">
                    <p>D’Lira, sabit değerli bir kripto para olarak pek çok alanda kullanılabilir:</p>
                    <div className="flex space-x-2">

                        <ul className='list-disc list-inside space-y-2 text-[#3C3C43D9]'>
                            <li>Kripto para işlemleri: Diğer kripto para birimleriyle hızlı ve ucuz şekilde takas yapılabilir.</li>
                            <li>DeFi uygulamaları: Borç alma/verme, staking, yield farming gibi merkeziyetsiz finans işlemlerinde kullanılabilir.</li>
                            <li>Uluslararası para transferleri: Hızlı ve düşük maliyetli para transferi yapılabilir.</li>
                            <li>Ticaret ve ödemeler: Sabit değerli bir ödeme aracı olarak kullanılabilir.</li>
                        </ul>
                    </div>
                </div>
            ),
            contentType: "custom"
        },
        {
            id: 5,
            title: "DLIRA hangi ağları desteklemektedir?",
            content: "D’Lira, Ethereum, Avalanche, Solana ve Binance Smart Chain gibi önde gelen blokzincir ağlarını desteklemektedir. Bu sayede geniş bir kullanım alanına ve esnekliğe sahiptir."

        },
        {
            id: 6,
            title: "D’Lira güvenli midir?",
            content: "Evet, D’Lira'nın değeri 1:1 oranında Türk Lirası rezervleri ile desteklenir ve bu rezervler şeffaf bir şekilde denetlenir."
        },
        {
            id: 7,
            title: "DLIRA transfer işlemleri nasıl gerçekleştirilir?",
            content: "D’Lira transfer işlemleri, desteklediği blokzincir ağları üzerinden yapılır. Kullanıcılar, cüzdan adreslerine dLira gönderebilir veya alabilirler."
        },
        {
            id: 8,
            title: "D’Lira hangi kripto borsalarında listeleniyor?",
            content: "D’Lira, destekleyen merkezi ve merkeziyetsiz kripto para borsalarında listelenmektedir. Hangi borsalarda işlem görebileceğini görmek için güncel listeye web sitemizden ulaşabilirsiniz.",
        },
        {
            id: 9,
            title: "DLIRA için hangi cüzdanlar kullanılabilir?",
            content: "D’Lira, Ethereum, Avalanche ve diğer desteklediği blokzincirlerde kullanılan cüzdanlarla uyumludur. Metamask, Trust Wallet gibi popüler cüzdanlarla kolayca kullanılabilir."
        },
        {
            id: 10,
            title: "D’Lira'nın değeri nasıl korunuyor?",
            content: "Her bir D’Lira, Türk Lirası cinsinden bir rezerv ile tamamen desteklenir. Bu rezervler güvenli hesaplarda tutulur ve düzenli olarak denetlenir."
        },
        {
            id: 11,
            title: "D’Lira ile DeFi platformlarında nasıl işlem yapılır?",
            content: "D’Lira’yı destekleyen DeFi platformlarına bağlanarak staking, yield farming ve diğer finansal işlemler gerçekleştirilebilir."
        }
    ]

    const PlatformSteps = [
        {
            title: 'DLIRA alımı',
            description: "DyorEX mobil uygulaması üzerinde bir hesap oluşturun ve prestarter bölümünden DLIRA'yi seçerek satın alma işlemizi gerçekleştirin.",
            image: PlatformBanner
        },
        {
            title: 'DLIRA satımı',
            description: "DyorEX mobil veya dyorex.com üzerinde Cüzdan sayfanıza gidin. Cüzdan Bakiyeleriniz kısmından DLIRA’yi seçin ve DLIRA/ TRY çiftini seçip satmak istediğiniz miktarı girin ve DLIRA SAT butonuna tıklayarak Türk Lirası’na.",
            image: PlatformBanner2
        },
        {
            title: 'Hızlı ve güvenli transfer',
            description: "DyorEX mobil veya dyorex.com üzerinde Cüzdan sayfanıza gidin. Ana hesap bakiyenizin altında bulunan Çekme butonuna tıklayın. Ardından DLIRA’yi seçip çekme miktarınızı ve adresinizi yazın ve işlemi tamamlayın.",
            image: PlatformBanner3
        }
    ];
    return (
        <>
            <Hero />
            {/* WHAT */}
            <Section
                id='what-is-dtl'
            >
                <div className="container mx-auto">
                    <div className="px-4 md:px-8 py-8 lg:py-16 flex lg:flex-row flex-col-reverse justify-between items-center gap-10">
                        <div className="flex flex-col lg:items-start items-center text-center lg:text-left gap-3 lg:w-[625px] w-full">
                            <h3 className="text-2xl text-[#133757] font-medium">
                                DLIRA Nedir?
                            </h3>
                            <h4 className="lg:text-5xl text-3xl text-black">
                                <span className="font-medium">DLira</span> ile Kripto Dünyasının Kapılarını Aralayın
                            </h4>
                            <p className="text-lg text-[#4F4F4F]">
                                <span className="font-semibold">DLIRA, DyorEX</span> tarafından yönetilen, Ethereum, Solana ve Tron gibi 3 farklı blok zincirinde
                                işlem görebilen <span className="font-semibold">Türk Lirası'na</span> endeksli bir stabil kripto para birimidir.
                                <span className="font-semibold">1 DLIRA 1 Türk Lirası'na eşittir</span> ve nakit paraya dönüştürülebilmektedir.
                                DLira ile banka hesaplarınız sayesinde kolayca itibari para transferi yapabilir, giriş-çıkış işlemlerinizi güvenli ve pratik bir
                                biçimde yönetme olanağına sahip olabilirsiniz.
                            </p>
                        </div>
                        <div className="w-full lg:w-auto">
                            <img
                                src={WhatIsDtl}
                                alt="Hero"
                                className="w-full md:w-[400px] lg:w-full mx-auto"
                            />
                        </div>
                    </div>
                </div>
            </Section>
            {/* HOW IS USE */}
            <Section
                id={'how-to-use-dtl'}
            >
                <div className="container mx-auto lg:px-0 px-2">
                    <div className="md:px-8 lg:px-[200px] py-8 lg:py-16 flex flex-col justify-between items-center gap-10">
                        <div className="flex flex-col justify-center items-center gap-[10px] text-center px-4">
                            <p className="text-3xl lg:text-5xl font-medium">
                                DLIRA Nasıl Kullanılır?
                            </p>
                            <p className="text-xl lg:text-2xl text-[#656565]">
                                D'Lira (DLIRA) satın almak ve diğer işlemlerde kullanmak oldukça kolaydır.
                            </p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-8 w-full px-4">
                            {steps.map((step, index) => (
                                <div key={index} className="flex justify-center">
                                    <OnboardingStepCard
                                        number={step.number}
                                        image={step.image}
                                        title={step.title}
                                    />
                                </div>
                            ))}
                        </div>

                        <Button
                            size="medium"
                            variant="solid"
                            isLoading={false}
                            href={'https://www.dyorex.com/exchange/ex/trade/advanced/DLIRA-TRY'}
                            styles={{
                                baseColor: "bg-[#133757]",
                                textColor: "text-white",
                                hoverBgColor: "hover:bg-[#0E2D42]",
                                focusRingColor: "focus:ring-[#133757]",
                                activeBgColor: "active:bg-[#0E2D42]",
                            }}
                        >
                            Hemen Satın Al
                        </Button>
                    </div>
                </div>

            </Section>

            {/* Features */}
            <Section
                id={'features'}
            >
                <div className='container mx-auto lg:px-0 px-2'>
                    <div className='bg-[#F0F3F8] rounded-[32px] px-4 md:px-8  py-8 lg:py-16 mb-[50px]'>
                        <div>
                            <div className='text-center flex flex-col gap-2 mb-[47px]'>
                                <p className='lg:text-2xl text-lg text-[#133757] font-medium'>Özellikler</p>
                                <p className='lg:text-5xl text-3xl font-medium'>D’Lira’nın Benzersiz Özellik ve Avantajları</p>
                            </div>
                        </div>

                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 mb-5'>
                            <div className="bg-transparent border rounded-[20px] lg:pl-7 shadow-sm">
                                <div className="flex lg:flex-col flex-col-reverse justify-around lg:items-end items-center text-left h-full lg:py-8 py-6">
                                    <div className="h-full flex flex-col justify-around lg:space-y-6 space-y-4 px-4 lg:px-0">
                                        <h3 className="text-[32px] font-medium text-black">
                                            Kripto Dünyasına Giriş ve Çıkışlarınızı Kolaylaştırın
                                        </h3>
                                        <div className="space-y-6">
                                            <p className="lg:text-[#4F4F4F] text-black text-xl font-light">
                                                D'Lira (DLIRA) kripto yatırım dünyasına giriş ve çıkışlarınızı kolaylaştıran bir araçtır.
                                                Kripto dünyasına adım atarken nakit varlıklarınız ile DLIRA'ye geçiş yaparak en masrafsız ve en hızlı şekilde kripto para
                                                dünyasına dahil olmuş olursunuz.
                                            </p>
                                            <p className="lg:text-[#4F4F4F] text-black text-xl font-light">
                                                D'Lira 1 Türk Lirası'na sabitlendiği için, kullanıcılar kripto piyasasındaki dalgalanmalardan etkilenmezler.
                                                Bu da riski minimize ederek güvenli işlemler yapılmasını sağlar.
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="lg:block hidden">
                                            <div className="flex items-end justify-end self-end mt-auto">
                                                <img src={FeaturesBanner4} alt="Kripto Dünyasına Giriş ve Çıkışlarınızı Kolaylaştırın" />
                                            </div>
                                        </div>
                                        <div className="lg:hidden block">
                                            <div className="flex items-center justify-center self-center mt-auto">
                                                <img src={FeaturesBanner4Mobile} alt="Kripto Dünyasına Giriş ve Çıkışlarınızı Kolaylaştırın" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-rows-2 gap-5'>
                                <div className="bg-transparent border rounded-[20px] px-3 shadow-sm">
                                    <div className="flex lg:flex-row flex-col-reverse justify-around lg:items-end items-center text-left h-full lg:py-0 py-4">
                                        <div className="lg:w-[280px] h-full flex flex-col justify-center">
                                            <h3 className="text-[32px] font-medium text-black mb-4">
                                                Hızlı İşlemler
                                            </h3>
                                            <p className="lg:text-[#4F4F4F] text-black text-xl font-light">
                                                D'Lira, blokzincir teknolojisi kullanarak, geleneksel bankacılık sistemine kıyasla daha çok
                                                daha hızlı transferler yapılmasına olanak tanır.
                                                Özellikle sınır ötesi transferlerde hız ve düşük maliyet büyük avantaj sağlar.
                                            </p>
                                            <br />
                                        </div>
                                        <div>
                                            <img src={FeaturesBanner2} alt="Hızlı İşlemler" className='lg:block hidden' />
                                            <img src={FeaturesBanner2Mobile} alt="Hızlı İşlemler" className='lg:hidden block' />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='bg-transparent border rounded-[20px] px-3 shadow-sm'

                                >
                                    <div className="flex lg:flex-row flex-col-reverse justify-around lg:items-end items-center text-left h-full lg:py-0 py-4">
                                        <div className='lg:w-[280px] h-full flex flex-col justify-center'>
                                            <h3 className='text-[32px] font-medium text-black mb-4'>
                                                Daha Düşük Komisyonlar
                                            </h3>
                                            <p className='lg:text-[#4F4F4F] text-black  text-xl font-light'>
                                                Geleneksel bankacılık sistemine kıyasla dLira ile yapılan işlemler, özellikle uluslararası transferlerde daha düşük ücretlerle
                                                gerçekleşir.
                                            </p><br />
                                        </div>
                                        <div>
                                            <img src={FeaturesBanner3} alt="Daha Düşük Komisyonlar" className='lg:block hidden' />
                                            <img src={FeaturesBanner3Mobile} alt="Daha Düşük Komisyonlar" className='lg:hidden block' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                            className='bg-white border rounded-[20px] lg:px-[50px] px-3  shadow-sm  overflow-hidden flex lg:flex-row flex-col-reverse justify-around items-center text-left lg:py-0 py-4'

                        >
                            <div className='lg:w-[450px]'>
                                <h3 className='text-[32px] font-medium text-black mb-4'>
                                    7/24 Erişim ve Transfer İmkanı
                                </h3>
                                <p className='lg:text-[#4F4F4F] text-black  text-xl font-light text-left'>
                                    Geleneksel bankalar belirli çalışma saatleri içinde hizmet verirken, <span className='font-semibold'>D’Lira ile 7/24 Türk Lirası değerinde</span> transfer ve alım satım yapabilirsiniz.
                                </p><br />

                            </div>
                            <div>
                                <img src={FeaturesBanner} alt="" className='w-full lg:block hidden' />
                                <img src={FeaturesBannerMobile} alt="" className='w-full lg:hidden block' />
                            </div>

                        </div>

                    </div>
                </div>
            </Section>

            {/* road map */}
            <Section className='mb-[50px] '>

                <div className='container mx-auto lg:px-0 px-2'>
                    <div className='rounded-[32px] mb-[50px]'>
                        <RoadMap />
                    </div>
                </div>

            </Section>


            {/* platform progress */}
            <Section>

                <div className='container mx-auto lg:px-0 px-2'>
                    <ProgressivePlatform steps={PlatformSteps} />
                </div>

            </Section>

            {/* Contact form */}
            <Section
                id={'contact-form'}
            >
                <div className='container mx-auto lg:px-0 px-2'>
                    <div className='bg-[#133757] rounded-[32px] px-4 md:px-8  py-8 lg:py-16'>
                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 lg:px-[70px]'>
                            <div className='flex flex-col justify-around gap-10'>
                                <div className='flex flex-col gap-[17px]'>
                                    <h3
                                        className='text-white text-normal text-6xl font-semibold'
                                    >
                                        Yardıma ihtiyacınız var mı?
                                    </h3>
                                    <p
                                        className='text-white text-2xl font-medium'
                                    >
                                        Tüm sorun ve taleplerinizi bu iletişim formunu kullanarak bize gönderebilirsiniz.
                                        Dilerseniz aşağıda bulunan diğer iletişim adresleri üzerinden de bize ulaşabilirsiniz.
                                    </p>
                                </div>
                                <div className='flex flex-col  items-start justify-center gap-5'>
                                    <div className='flex items-center gap-5 text-white'>
                                        <Mail color="#78CCD4" width={32} />
                                        <span>destek@dyorex.com</span>
                                    </div>
                                    <div className='flex items-center gap-5 text-white'>

                                        <Phone color="#78CCD4" width={32} />
                                        <span>O850 532 39 76</span>
                                    </div>
                                    {/* <div className='flex items-center gap-5 text-white'>

                                        <MapPin color="#78CCD4" width={32} />
                                        <span>123 Street 487 House</span>
                                    </div> */}
                                </div>
                            </div>
                            <div>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>

            </Section>

            {/* S.S.S */}

            <Section
                id={'faq'}
            >

                <div className='container mx-auto'>
                    <div className='px-4 md:px-8 lg:px-[100px] py-8 lg:py-16 flex lg:flex-row flex-col items-start justify-center gap-5'>
                        <h3 className='font-normal text-6xl lg:max-w-[280px] text-left mx-auto'>Sık Sorulan Sorular</h3>
                        <Accordion
                            items={accordionItems}
                            iconType='plus'
                        />
                    </div>
                </div>


            </Section>

        </>
    )
}

export default Home