import React from 'react';
import Logo from '../../assets/images/svg/dLira-footer.svg';

const Footer = () => {
  return (
    <footer className="w-full bg-white py-4 px-4 sm:px-6 border-t">
      <div className="max-w-7xl mx-auto">
        {/* Top Section - Logo and Navigation */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-6 space-y-4 sm:space-y-0">
          {/* Logo Section */}
          <div className="flex lg:justify-center justify-start">
            <img
              src={Logo}
              alt="D-Lira Logo"
              className="w-32 sm:w-40" // Controlled width for better scaling
            />
          </div>

          {/* Navigation Links */}
          <div className="flex flex-wrap justify-center sm:justify-end gap-4 sm:space-x-6 text-sm">
            <a target='_blank' rel="noopener noreferrer" href="https://www.dyorex.com/exchange/session/register" className="text-gray-600 hover:text-gray-900">Bireysel</a>
            <a target='_blank' rel="noopener noreferrer" href="https://www.dyorex.com/exchange/session/register-corporate" className="text-gray-600 hover:text-gray-900">Kurumsal</a>
            {/* <a href="/" className="text-gray-600 hover:text-gray-900">Geliştirici için</a> */}
            <a target='_blank' rel="noopener noreferrer" href="https://www.dyorex.com/" className="text-gray-600 hover:text-gray-900">Develop</a>
          </div>
        </div>

        {/* Disclaimer Text */}
        <div className="text-xs text-gray-500 space-y-2 mb-4">
          <div className="space-y-2">
            <p className="leading-relaxed">Üretilen ve iade edilen toplamlar, 24 saatlik dönem içinde DLira’nın tahmini toplam hacmini ifade eder.</p>

          </div>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col sm:flex-row justify-between lg:items-center items-start text-xs text-gray-500 space-y-2 sm:space-y-0">
          <p>© 2024 D-Lira, Tüm hakları saklıdır.</p>
          <div className="space-x-4">
            <a href="/" className="hover:text-gray-900">Yasal Düzenlemeler & Gizlilik</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;