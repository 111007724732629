import React from 'react';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            topic: 'Destek Talebi',
            name: '',
            email: '',
            message: ''
        }
    });

    const [focusedField, setFocusedField] = React.useState(null);

    const selectedTopic = watch('topic');

    const onSubmit = (data) => {
        console.log('Form submitted:', data);
    };

    const topics = [
        'Destek Talebi',
        'Bilgilendirme',
        'Şikayet/Öneri',
        'Diğer'
    ];

    return (
        <div className="w-full max-w-[640px] mx-auto p-6 bg-white rounded-3xl shadow-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                {/* Konu Seçimi */}
                <div className="space-y-2">
                    <label className="block text-xl font-semibold text-[#78CCD4]">Konu</label>
                    <div className="flex flex-wrap gap-2">
                        {topics.map((topic) => (
                            <button
                                key={topic}
                                type="button"
                                onClick={() => setValue('topic', topic)}
                                className={`px-4 py-2 text-sm rounded-full ${selectedTopic === topic
                                        ? 'bg-[#78CCD4] text-white'
                                        : 'bg-white border border-[#787878] text-[#787878]'
                                    }`}
                            >
                                {topic}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Ad Soyad */}
                <div>
                    <label
                        htmlFor="name"
                        className={`block text-lg font-semibold transition-colors duration-200 ${focusedField === 'name' ? 'text-[#78CCD4]' : 'text-[#CDCDCD]'
                            }`}
                    >
                        Ad Soyad
                    </label>
                    <input
                        {...register('name', { required: 'Ad Soyad zorunludur' })}
                        type="text"
                        id="name"
                        onFocus={() => setFocusedField('name')}
                        onBlur={() => setFocusedField(null)}
                        className="mt-1 w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78CCD4]"
                    />
                    {errors.name && (
                        <span className="text-red-500 text-sm">{errors.name.message}</span>
                    )}
                </div>

                {/* Email */}
                <div>
                    <label
                        htmlFor="email"
                        className={`block text-lg font-semibold transition-colors duration-200 ${focusedField === 'email' ? 'text-[#78CCD4]' : 'text-[#CDCDCD]'
                            }`}
                    >
                        Email adresi
                    </label>
                    <input
                        {...register('email', {
                            required: 'Email zorunludur',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Geçersiz email adresi'
                            }
                        })}
                        type="email"
                        id="email"
                        onFocus={() => setFocusedField('email')}
                        onBlur={() => setFocusedField(null)}
                        className="mt-1 w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78CCD4]"
                    />
                    {errors.email && (
                        <span className="text-red-500 text-sm">{errors.email.message}</span>
                    )}
                </div>

                {/* Mesajınız */}
                <div>
                    <label
                        htmlFor="message"
                        className={`block text-lg font-semibold transition-colors duration-200 ${focusedField === 'message' ? 'text-[#78CCD4]' : 'text-[#CDCDCD]'
                            }`}
                    >
                        Mesajınız
                    </label>
                    <textarea
                        {...register('message', {
                            required: 'Mesaj zorunludur',
                            minLength: {
                                value: 10,
                                message: 'Mesaj en az 10 karakter olmalıdır'
                            }
                        })}
                        id="message"
                        rows={4}
                        onFocus={() => setFocusedField('message')}
                        onBlur={() => setFocusedField(null)}
                        className="mt-1 w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78CCD4]"
                    />
                    {errors.message && (
                        <span className="text-red-500 text-sm">{errors.message.message}</span>
                    )}
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full py-3 px-4 bg-[#78CCD4] hover:bg-[#6BB8C7] text-white rounded-full transition-colors duration-200"
                >
                    Mesajı Gönder
                </button>
            </form>
        </div>
    );
};

export default ContactForm;