import React from "react";
import { Link } from "react-router-dom";

const Button = ({
  children,
  size = "medium",
  variant = "solid",
  isLoading = false,
  disabled = false,
  leftIcon,
  rightIcon,
  styles = {},
  className = "",
  // Yeni link özellikleri
  href,
  to,
  target,
  rel,
  ...props
}) => {
  const defaultStyles = {
    // Base styles
    baseColor: variant === "solid" ? "bg-blue-600" : "bg-transparent",
    textColor: variant === "solid" ? "text-white" : "text-blue-600",
    borderColor: variant === "outline" ? "border-blue-600" : "border-transparent",
    
    // Hover states
    hoverBgColor: variant === "solid" ? "hover:bg-blue-700" : "hover:bg-blue-50",
    hoverTextColor: variant === "solid" ? "hover:text-white" : "hover:text-blue-700",
    hoverBorderColor: variant === "outline" ? "hover:border-blue-700" : "hover:border-transparent",
    
    // Active states
    activeBgColor: variant === "solid" ? "active:bg-blue-800" : "active:bg-blue-100",
    activeTextColor: variant === "solid" ? "active:text-white" : "active:text-blue-800",
    activeBorderColor: variant === "outline" ? "active:border-blue-800" : "active:border-transparent",
    
    // Focus states
    focusRingColor: "focus:ring-blue-500",
    
    // Disabled states
    disabledBgColor: variant === "solid" ? "bg-gray-300" : "bg-transparent",
    disabledTextColor: variant === "solid" ? "text-gray-100" : "text-gray-300",
    disabledBorderColor: variant === "outline" ? "border-gray-300" : "border-transparent",
    
    // Loading state
    loadingBgColor: variant === "solid" ? "bg-blue-400" : "bg-transparent",
    loadingTextColor: variant === "solid" ? "text-white" : "text-blue-400",
    loadingBorderColor: variant === "outline" ? "border-blue-400" : "border-transparent",
  };

  const mergedStyles = { ...defaultStyles, ...styles };

  const sizeClasses = {
    small: "px-3 py-1.5 text-sm",
    medium: "px-4 py-2 text-base",
    large: "px-6 py-3 text-lg"
  };

  const LoadingSpinner = () => (
    <svg 
      className="animate-spin -ml-1 mr-2 h-4 w-4" 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24"
    >
      <circle 
        className="opacity-25" 
        cx="12" 
        cy="12" 
        r="10" 
        stroke="currentColor" 
        strokeWidth="4"
      />
      <path 
        className="opacity-75" 
        fill="currentColor" 
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  const commonClasses = `
    inline-flex items-center justify-center
    ${sizeClasses[size]}
    rounded-md
    font-medium
    border-2
    transition-colors
    duration-200
    focus:outline-none focus:ring-2 focus:ring-offset-2 ${mergedStyles.focusRingColor}
    ${disabled ? `
      ${mergedStyles.disabledBgColor}
      ${mergedStyles.disabledTextColor}
      ${mergedStyles.disabledBorderColor}
      cursor-not-allowed
    ` : isLoading ? `
      ${mergedStyles.loadingBgColor}
      ${mergedStyles.loadingTextColor}
      ${mergedStyles.loadingBorderColor}
      cursor-wait
    ` : `
      ${mergedStyles.baseColor}
      ${mergedStyles.textColor}
      ${mergedStyles.borderColor}
      ${mergedStyles.hoverBgColor}
      ${mergedStyles.hoverTextColor}
      ${mergedStyles.hoverBorderColor}
      ${mergedStyles.activeBgColor}
      ${mergedStyles.activeTextColor}
      ${mergedStyles.activeBorderColor}
    `}
    ${className}
  `;

  const content = (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && leftIcon && <span className="mr-2">{leftIcon}</span>}
      {children}
      {!isLoading && rightIcon && <span className="ml-2">{rightIcon}</span>}
    </>
  );

  // Dış link için
  if (href) {
    return (
      <a
        href={href}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : rel}
        className={commonClasses}
        {...props}
      >
        {content}
      </a>
    );
  }

  // React Router için iç link
  if (to) {
    return (
      <Link
        to={to}
        className={commonClasses}
        {...props}
      >
        {content}
      </Link>
    );
  }

  // Normal buton
  return (
    <button
      disabled={disabled || isLoading}
      className={commonClasses}
      {...props}
    >
      {content}
    </button>
  );
};

export default Button;