import React from 'react'
import HeroImg from '../assets/images/img/hero.png'
import Button from './Button';

const Hero = () => {
    return (
        <>
            <div
                className='px-4 md:px-8 lg:px-[200px] py-8 lg:py-16 gap-10'
                style={{
                    background: "rgb(236, 236, 236)",
                    backgroundImage: "linear-gradient(90deg, rgba(236, 236, 236, 1) 20%, rgba(180, 209, 225, 1) 100%)"
                }}
            >
                <div className='container mx-auto flex lg:flex-row flex-col-reverse justify-around items-center'>
                    <div className='flex flex-col gap-[28px] lg:w-[625px] w-full text-center lg:text-left'>
                        <p className='text-5xl leading-[60px]'>
                            Türkiye'nin Stable Coini <span className='font-semibold text-[#133757]'>DLira</span> ile Tanışın
                        </p>
                        <p className='text-2xl text-[#4F4F4F]'>
                            <span className='font-semibold'>DLira (DLIRA)</span>
                            , 1:1 oranında <span className='font-semibold'>Türk Lirası'na</span> sabitlenmiş ve rezervler ile %100 desteklenen bir stabil kripto para birimidir.
                        </p>
                        <div className='flex justify-center lg:justify-start'>
                            <Button
                                size="large"
                                variant="solid"
                                isLoading={false}
                                href={'https://www.dyorex.com/exchange/ex/trade/advanced/DLIRA-TRY'}
                                styles={{
                                    baseColor: "bg-[#133757]",
                                    textColor: "text-white",
                                    hoverBgColor: "hover:bg-[#0E2D42]",
                                    focusRingColor: "focus:ring-[#133757]",
                                    activeBgColor: "active:bg-[#0E2D42]",
                                }}
                            >
                                DLIRA Satın Al
                            </Button>
                        </div>
                    </div>
                    <div className='w-full lg:w-auto mb-8 lg:mb-0'>
                        <img
                            src={HeroImg}
                            alt="Hero"
                            className='w-full md:w-[400px] lg:w-full mx-auto'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero