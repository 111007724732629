import React from 'react';

const OnboardingStepCard = ({ number, image, title }) => {
  return (
    <div className="flex flex-col items-center w-full lg:max-w-[300px] lg:bg-white bg-[#ECECEC] lg:px-0 lg:py-0 px-2 py-6 lg:rounded-none rounded-[10px]">
      {/* Step number */}
      <div className="w-full text-left mb-8">
        <span className="text-black text-2xl">
          {number.padStart(2, '0')}
        </span>
      </div>

      {/* Image container */}
      <div className="w-full h-32 flex items-center justify-center mb-4 ">
        <img
          src={image}
          alt={title}
          className="full"
        />
      </div>

      {/* Title text */}
      <div className="text-left w-full">
        <p className="text-[#5F5F5F] text-md leading-normal">
          {title}
        </p>
      </div>
    </div>
  );
};

export default OnboardingStepCard;