import React, { useState, useEffect, useCallback } from 'react';
import { Menu, X, ChevronDown } from 'lucide-react';
import Logo from '../../assets/images/svg/Logo.svg';
import LogoMobil from '../../assets/images/svg/logo_mobil.svg'
import { useLocation, Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const location = useLocation();

    const menuItems = [
        {
            id: 1,
            title: 'DLira Nedir?',
            path: '/#what-is-dtl',
            isButton: false,
        },
        {
            id: 2,
            title: 'Özellikler',
            path: '/#features',
            isButton: false,
        },
        { id: 3, title: 'İletişim', path: '/#contact-form', isButton: false },
        { id: 4, title: 'S.S.S.', path: '/#faq', isButton: false },
        { id: 5, title: 'DLIRA Satın Al', path: 'https://www.dyorex.com/exchange/ex/trade/advanced/DLIRA-TRY', isButton: true },
    ];

    // Hash'e scroll yapma fonksiyonu
    const scrollToHash = useCallback((hash) => {
        setIsOpen(false);
        setActiveDropdown(null);

        if (location.pathname === '/') {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    const navbarHeight = 85;
                    const elementPosition = element.offsetTop - navbarHeight;
                    window.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth'
                    });
                }
            }, 0);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (location.hash) {
            scrollToHash(location.hash);
        }
    }, [location, scrollToHash]);

    // Body scroll lock when menu is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const MenuLink = ({ item }) => {
        const hasDropdown = item.dropdownItems && item.dropdownItems.length > 0;

        if (item.isButton) {
            return (
                <Link
                    to={item.path}
                    onClick={() => scrollToHash(item.path.substring(1))}
                    className="bg-[#7FD1E1] text-white px-5 py-2 rounded-md hover:bg-[#6BB8C7] transition duration-300"
                >
                    {item.title}
                </Link>
            );
        }

        if (hasDropdown) {
            return (
                <div className="relative group">
                    <button
                        onClick={() => setActiveDropdown(activeDropdown === item.id ? null : item.id)}
                        className="flex items-center text-gray-300 hover:text-white transition duration-300"
                    >
                        {item.title}
                        <ChevronDown
                            size={16}
                            className={`ml-1 transform transition-transform duration-300 ${activeDropdown === item.id ? 'rotate-180' : ''}`}
                        />
                    </button>

                    <div className="hidden md:block absolute left-0 mt-2 w-48 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                        <div className="bg-[#133757] rounded-md shadow-lg py-2">
                            {item.dropdownItems.map((dropdownItem) => (
                                <Link
                                    key={dropdownItem.id}
                                    to={dropdownItem.path}
                                    onClick={() => scrollToHash(dropdownItem.path.substring(1))}
                                    className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-[#1a4a75] transition duration-300"
                                >
                                    {dropdownItem.title}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Link
                to={item.path}
                onClick={() => scrollToHash(item.path.substring(1))}
                className="text-gray-300 hover:text-white transition duration-300"
            >
                {item.title}
            </Link>
        );
    };

    return (
        <nav className="bg-[#133757] fixed top-0 left-0 right-0 z-50">
            <div className="max-w-[1440px] mx-auto px-5">
                <div className="flex justify-between items-center h-[85px]">
                    {/* Logo */}
                    <div className="flex items-center">
                        <Link to="#" className="flex items-center">
                            <img src={Logo} alt="Logo" />
                            <span className="ml-2 text-[#B8C9E2] text-sm hidden lg:block border-l lg:pl-2">
                                DyorEX’in Stable Coin’i
                            </span>
                        </Link>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden md:flex items-center space-x-8">
                        {menuItems.map((item) => (
                            <MenuLink key={item.id} item={item} />
                        ))}
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="md:hidden flex items-center">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-gray-300 hover:text-white focus:outline-none"
                        >
                            {isOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>
                </div>

                {/* Mobile Menu Overlay */}
                {isOpen && (
                    <div
                        className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
                        onClick={() => setIsOpen(false)}
                    />
                )}

                {/* Mobile Side Menu */}
                <div
                    className={`md:hidden fixed top-0 left-0 w-72 h-full bg-white z-50 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'
                        }`}
                >
                    <div className="p-4 border-b border-[#DCDCDC]">
                        <div className="flex justify-between items-center">
                            <Link to="#" className="flex items-center">
                                <img src={LogoMobil} alt="Logo" className="h-8" />
                            </Link>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="text-gray-300 hover:text-white focus:outline-none"
                            >
                                <X size={24} />
                            </button>
                        </div>
                    </div>
                    <div className="py-4">
                        {menuItems
                            .filter(item => !item.isButton)
                            .map(item => (
                                <div key={item.id}>
                                    {item.dropdownItems ? (
                                        <div className="px-4">
                                            <button
                                                onClick={() => setActiveDropdown(activeDropdown === item.id ? null : item.id)}
                                                className="flex items-center justify-between w-full py-2 text-gray-300 hover:text-white transition duration-300"
                                            >
                                                {item.title}
                                                <ChevronDown
                                                    size={16}
                                                    className={`transform transition-transform duration-300 ${activeDropdown === item.id ? 'rotate-180' : ''
                                                        }`}
                                                />
                                            </button>
                                            <div
                                                className={`transition-all duration-300 ease-in-out overflow-hidden ${activeDropdown === item.id ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'
                                                    }`}
                                            >
                                                {item.dropdownItems.map((dropdownItem) => (
                                                    <Link
                                                        key={dropdownItem.id}
                                                        to={dropdownItem.path}
                                                        onClick={() => scrollToHash(dropdownItem.path.substring(1))}
                                                        className="block pl-4 py-2 text-sm text-[#133757] font-medium hover:text-white hover:bg-[#1a4a75] transition duration-300"
                                                    >
                                                        {dropdownItem.title}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <Link
                                            to={item.path}
                                            onClick={() => scrollToHash(item.path.substring(1))}
                                            className="block px-4 py-2 text-[#133757] font-medium hover:text-white transition duration-300 border-b-[0.5px] border-[#DCDCDC]"
                                        >
                                            {item.title}
                                        </Link>
                                    )}
                                </div>
                            ))
                        }

                        {/* Mobile Menu Buy Button */}
                        {menuItems
                            .filter(item => item.isButton)
                            .map(item => (
                                <Link
                                    key={item.id}
                                    to={item.path}
                                    onClick={() => scrollToHash(item.path.substring(1))}
                                    className="block mx-1 mt-3 bg-[#7FD1E1] text-white px-4 py-2 rounded-md hover:bg-[#6BB8C7] text-center transition duration-300"
                                >
                                    {item.title}
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;