import React, { useState } from 'react';
import { ChevronDown, Plus, ArrowDown, ChevronsDown, Minus } from 'lucide-react';

const icons = {
  chevron: {
    closed: ChevronDown,
    open: ChevronDown
  },
  plus: {
    closed: Plus,
    open: Minus
  },
  arrow: {
    closed: ArrowDown,
    open: ArrowDown
  },
  doubleChevron: {
    closed: ChevronsDown,
    open: ChevronsDown
  }
};

// İçerik render fonksiyonu
const renderContent = (content, type = 'text') => {
  switch (type) {
    case 'list':
      return (
        <ul className="list-disc list-inside space-y-2">
          {content.map((item, index) => (
            <li key={index} className="text-[#3C3C43D9]">{item}</li>
          ))}
        </ul>
      );
    
    case 'numbered':
      return (
        <ol className="list-decimal list-inside space-y-2">
          {content.map((item, index) => (
            <li key={index} className="text-[#3C3C43D9]">{item}</li>
          ))}
        </ol>
      );
    
    case 'custom':
      // JSX içerik için doğrudan render
      return content;
      
    case 'text':
    default:
      return <p className="text-[#3C3C43D9]">{content}</p>;
  }
};

const Accordion = ({ items, iconType = 'chevron', iconClassName = '' }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const iconSet = icons[iconType] || icons.chevron;

  return (
    <div className="w-full mx-auto border rounded-lg divide-y">
      {items.map((item, index) => {
        const isOpen = openIndex === index;
        const Icon = isOpen ? iconSet.open : iconSet.closed;

        return (
          <div
            key={index}
            className="overflow-hidden"
          >
            <button
              onClick={() => toggleItem(index)}
              className={`w-full flex items-center justify-between p-4 bg-white hover:bg-gray-50 transition-colors ${
                isOpen ? 'bg-gray-50' : ''
              }`}
            >
              <span className="font-bold lg:text-xl text-xs text-gray-900 text-left">{item.title}</span>
              <Icon 
                className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                  iconType !== 'plus' && isOpen ? 'rotate-180' : ''
                } ${iconClassName}`}
              />
            </button>
            
            <div
              className={`overflow-hidden transition-all duration-200 ${
                isOpen ? 'max-h-96' : 'max-h-0'
              }`}
            >
              <div className="p-4 bg-gray-50 border-t">
                {renderContent(item.content, item.contentType)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;